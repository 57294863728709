<template>
  <v-container fluid>
    <v-row>

      <v-col cols="12" md="6" align-self="center">
        <b-form-checkbox v-model="group.enableTaxOverriding" @change="update" class="mx-1">{{$t('erp.lang_enableTaxOverriding')}}
        </b-form-checkbox>
      </v-col>
      <v-col cols="12" md="6" class="d-flex align-center">
        <b-form-input v-if="group.enableTaxOverriding" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :placeholder="$t('erp.lang_tax_value')" type="number"
                      @focus="showTouchKeyboard" class="mx-1" @input="taxInput"
                      v-model="group.taxRate"/>
        <v-btn v-if="group.enableTaxOverriding"
               :disabled="this.loadingName || (this.group && this.group.CustGroupName === '')"
               :loading="this.loadingName"
               @click="update()" class="ml-0 "
               color="success">
          {{ this.$t('generic.lang_save') }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6">
        <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :placeholder="this.$t('customers.lang_nameCustomerGroup')"
                      :state="this.group && this.group.CustGroupName  !== ''"
                      @focus="showTouchKeyboard"
                      @keypress.enter="update()"
                      v-model="group.CustGroupName"/>
        <v-btn :disabled="this.loadingName || (this.group && this.group.CustGroupName === '')"
               :loading="this.loadingName"
               @click="update()" class="ml-0"
               color="success">
          {{ this.$t('generic.lang_save') }}
        </v-btn>
      </v-col>

      <v-col cols="12" md="6">
        <b-input-group append="%">
          <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL" :state="null"
                        @focus="showTouchKeyboard"
                        @keypress.enter="(loadApply = true) & setAll()" min="0"
                        :placeholder="this.$t('erp.lang_discountForAllWaregroupsInPercent')"
                        type="number"
                        v-model="discountAll"/>
        </b-input-group>
        <v-btn :disabled="this.discountAll === '' || this.loadApply" :loading="this.loadApply"
               @click="(loadApply = true) & setAll()" class="ml-0"
               color="primary">
          {{ this.$t('generic.lang_apply') }}
        </v-btn>
      </v-col>
      <v-col class="pa-0 ma-0" cols="12">
        <v-divider class="pa-0 ma-0"/>
        <v-card-title class="pa-0 ma-0">
          <v-row>

            <v-col class="pt-0 ma-0" cols="12">
              <div class="card-header-tab card-header">
                <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <b-input-group width="100%">
                    <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                  :placeholder="$t('datatables.lang_search')"
                                  @focus="showTouchKeyboard"
                                  v-model="search"/>
                  </b-input-group>
                </div>
                <div class="btn-actions-pane-right actions-icon-btn">
                  <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                            <span slot="button-content"><i
                                                class="pe-7s-menu btn-icon-wrapper"></i></span>
                    <div>
                      <h6 class="dropdown-header" tabindex="-1">
                        {{ this.$t('generic.lang_Options') }}</h6>

                      <!-- Excel Download: ONLY SELECTED ITEMS -->
                      <vue-excel-xlsx
                          :columns="excelColumns"
                          :data="selectedRows"
                          :filename="excelFileName"
                          :sheetname="'Sheet1'"
                          v-if="selectedRows.length > 0 && !noExcelExport"
                      >
                        <button class="dropdown-item" tabindex="0"
                                type="button"
                                v-if="selectedRows.length > 0">
                          <font-awesome-icon class="dropdown-icon success--text"
                                             icon="file-excel"/>
                          <span>Excel</span></button>

                      </vue-excel-xlsx>
                      <vue-excel-xlsx
                          :columns="excelColumns"
                          :data="filteredDatatableEntries"
                          :filename="excelFileName"
                          :sheetname="'Sheet1'"
                          v-if="selectedRows.length === 0"
                      >
                        <button class="dropdown-item" tabindex="0" type="button"
                                v-if="selectedRows.length === 0 ">
                          <font-awesome-icon class="dropdown-icon success--text"
                                             icon="file-excel"/>
                          <span>Excel</span></button>
                      </vue-excel-xlsx>
                    </div>
                  </b-dropdown>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
            :footer-props="footerProps"
            :headers="filteredDatatableHeaders"
            :items="filteredDatatableEntries"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="total"
            :show-select="true"
            footer-props.items-per-page-text="Einträge pro Seite"
            v-model="selectedRows"
        >

          <template v-slot:item.old_price="{ item }">

            <span>{{ item.old_price | currency }}</span>

          </template>

          <template v-slot:item.discount="{ item }">
            <b-input-group append="%">
              <b-form-input :data-layout="KEYBOARD.KEYSETS.NORMAL" @blur="setDiscount(item)"
                            @focus="showTouchKeyboard" @keypress.enter="setDiscount(item)"
                            v-model="item.discount"/>
            </b-input-group>

          </template>
        </v-data-table>
      </v-col>
      <v-col>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                              :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import {mapState} from "vuex";
import mixin from "../../../mixins/KeyboardMixIns";
import {library} from '@fortawesome/fontawesome-svg-core';
import {faFileExcel,} from '@fortawesome/free-solid-svg-icons';
import {faBarcodeRead} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
    faFileExcel, faBarcodeRead
);

export default {
  name: "CustomerGroupsItemsComponent",
  components: {
    'font-awesome-icon': FontAwesomeIcon
  },
  mixins: [mixin],
  data: () => {
    return {
      group: {},
      discountAll: "",
      loadingName: false,
      loadApply: false,
      excelFileName: "Price template table",
      dataToShow: [],
      search: '',
      awaitingSearch: null,
      total: 0,
      loading: false,
      selectedRows: [],
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      avtivateAll: false,
      disavtivateAll: false
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: "id",
        },
        {
          label: this.$t('erp.lang_itemgroup_name'),
          field: "itemname",
        },
        {
          label: this.$t('generic.lang_discount'),
          field: "discount",
        }
      ];
    },
    datatableHeaders: function () {
      return [
        {
          text: "ID",
          value: "id",
          sortable: true,
          hide: true
        },
        {
          text: this.$t('erp.lang_itemgroup_name'),
          value: "itemname",
          sortable: false
        },
        {
          text: this.$t('generic.lang_discount'),
          value: "discount",
        },
      ];
    },
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }

        headers.push(header);
      });

      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];
          }
        });

        dataToRender.push(row);
      });

      return dataToRender;
    }
  },

  mounted() {
    this.loadData();
    this.getDataFromApi();
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    }
  },

  methods: {
    taxInput() {
      if (Number(this.group.taxRate) < 0) {
        this.group.taxRate=0;
      }
    },
    set(ids, discount) {
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.SETDISCOUNT, {
        itemGroupDiscounts: discount,
        custGroupID: this.$route.params.id,
        itemGroupID: ids
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('generic.lang_ActionSuccessful')
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        if (this.loadApply) {
          this.loadApply = false;
          this.getDataFromApi();
          this.discountAll = "";
        }
      })
    },
    setDiscount(item) {
      this.set(item.id, item.discount);
    },
    setAll() {
      this.set([], this.discountAll);
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }

      params.custGroupID = this.$route.params.id;


      this.axios.get(ENDPOINTS.DATATABLES.CUSTOMERS.CUSTOMERGROUPS.GROUPSWIREGROUPS, {
        params: params
      }).then((res) => {
        this.total = parseInt(res.data.iTotalDisplayRecords);
        this.dataToShow = res.data.aaData;

        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    },
    loadData() {
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.GET, {
        custGroupID: this.$route.params.id
      }).then((res) => {
        this.group = res.data.formfillData.textFields;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      })
    },
    update() {
      this.loadingName = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.UPDATE, {
        custGroupID: this.$route.params.id,
        CustGroupName: this.group.CustGroupName,
        enableTaxOverriding: this.group.enableTaxOverriding,
        taxRate: this.group.taxRate,
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            color: "success",
            message: this.$t('customers.lang_customerUpdated')
          });
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain')
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      }).finally(() => {
        this.loadingName = false;
      })
    }
  }
}
</script>

<style scoped>

</style>