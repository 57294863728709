import { render, staticRenderFns } from "./CustomerGroupsItemsComponent.vue?vue&type=template&id=7ae6925a&scoped=true"
import script from "./CustomerGroupsItemsComponent.vue?vue&type=script&lang=js"
export * from "./CustomerGroupsItemsComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ae6925a",
  null
  
)

export default component.exports