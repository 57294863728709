<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('customers.lang_nav_custgroup') "
                :icon="icon"
                :subheading="$t('customers.lang_nav_editcustgroup')"
                show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <customer-group-edit-component />
        </div>
    </v-container>
</template>

<script>
    //components
    import PageTitle from "../../../Layout/Components/PageTitle.vue";
    import CustomerGroupEditComponent from "../../../components/customers/customergroup/CustomerGroupEditComponent";



    export default {
        components: {
            CustomerGroupEditComponent,
            PageTitle,

        },
        data: () => ({
            icon: "pe-7s-users icon-gradient bg-tempting-azure"
        })
    };
</script>

<style>
</style>