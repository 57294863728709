<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                {{this.$t('customers.lang_nav_editcustgroup')}}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>

            <v-card-text class="pa-0 ma-0" v-if="this.group && this.group.type == 2">
                <customer-groups-waregroups-component />
            </v-card-text>

            <v-card-text  class="pa-0 ma-0" v-if="this.group && this.group.type == 1">
                <customer-groups-items-component />
            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import CustomerGroupsItemsComponent from "./CustomerGroupsItemsComponent";
    import CustomerGroupsWaregroupsComponent from "./CustomerGroupsWaregroupsComponent";
    import {Events} from "../../../plugins/events";

    export default {
        name: "CustomerGroupEditComponent",
        components: {CustomerGroupsItemsComponent, CustomerGroupsWaregroupsComponent},
        data: () => {
            return {
                group: null
            }
        },
      mounted() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.GET, {
                    custGroupID: this.$route.params.id
                }).then((res) => {
                    this.group = res.data.formfillData.textFields;
                }).catch((err) => {
                    Events.$emit("showSnackbar", {
                        color: "error",
                        message: err.message
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>